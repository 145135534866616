.chart-container {
	position: relative;
	margin: auto;
	height: 80vh;
	width: 80vw;
}

@media only screen and (max-width: 768px) {
	.chart-container {
		height: 40vh;
		width: 100vw;
	}
}
