#lme-average, #minfin-average {
    display: inline-block;
}

.sign {
	margin: 0 4px;
}

#price {
	display: flex;
	height: 200px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

#price-container {
	width: 100%;
	height: 40%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

input {
	width: 30px;
	height: 20px;
    text-align: center;
    font-size: 16px;
    border: none;
	border-radius: 4px;
	transition: all .2s;
    box-shadow: 0px 0px 2px 1px #0f4c81;
}

input[type=text] {
	width: 100%;
}

input:focus {
	border: none !important;
	outline: none !important;
    box-shadow: 0px 0px 4px 2px #0f4c81 !important;
}

input[type=number] {
  -moz-appearance: textfield;
  margin: 0;
  padding: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  padding: 0;
}
